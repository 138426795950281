import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Profile.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";

export default function Influencer({
  isMobile,
  apiURL,
  authToken,
  creators,
  templates,
  services,
}) {
  const [creatorData, setCreatorData] = useState({ companyname: "" });
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const { id } = useParams();

  useEffect(() => {
    if (!isEmpty(creators)) {
      creators.map((creator) => {
        if (creator.id == id) {
          setCreatorData(creator);
        }
      });
    }
  }, [creators]);

  return (
    <div className="profile">
      <div className="profile_topnav">
        <div className="profile_topnav_back" onClick={() => navigate("/")}>
          <ArrowBackIcon /> Zurück
        </div>
      </div>
      <div className="profile_body">
        <div className="profile_body_bio">
          <div className="profile_body_bio_left">
            <img
              src={
                !isEmpty(creatorData.pictures)
                  ? JSON.parse(creatorData.pictures)[0].url
                  : ""
              }
              style={{ height: "100%", width: "100%", borderRadius: "9999px" }}
            />
          </div>
          <div className="profile_body_bio_right">
            {creatorData.companyname}
          </div>
        </div>
        <div className="profile_body_services">
          {templates.map((template) => (
            <div
              className="profile_body_listelement"
              onClick={() =>
                navigate("/influencer/" + id + "/service/" + template.id)
              }
              key={template.id + "inf"}>
              <div className="profile_body_listelement_top"></div>
              <div className="profile_body_listelement_bottom">
                {template.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 23px;
  color: #474747;
`;
