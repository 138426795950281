import styled from "styled-components/macro";
import React, { useEffect, useRef, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdContentPaste,
  MdDownload,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";
import { FaInstagram } from "react-icons/fa";

import "../styles/DeliverStory.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import Spinner from "../components/CTA/Spinner";

export default function Fulfill({
  isMobile,
  apiURL,
  authToken,
  creators,
  orders,
  reload,
  setReload,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [storyLink, setStoryLink] = useState("");
  const [videoUrl, setvideoUrl] = useState("");

  useEffect(() => {
    if (!isEmpty(orders)) {
      orders.map((order) => {
        if (order.id == id) {
          setStoryLink(order.delivery_storypost_url);
          setvideoUrl(order.merged_video_url);
        }
      });
    }
  }, [orders]);

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const file = new File([blob], "video.mp4", { type: "video/mp4" });

      if (
        navigator.canShare &&
        navigator.canShare({ files: [file] }) &&
        isIOS()
      ) {
        await navigator.share({
          title: "Mein letsfluence Video",
          text: "Auf Insta teilen",
          files: [file],
        });
      } else {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "video.mp4";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error sharing the file:", error);
    }
  };

  // upload profile data
  const handleUpload = async () => {
    setIsLoading(true);

    const resa = await fetch(`${apiURL}/api/orders/index.php?id=` + id, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        delivery_storypost_url: storyLink,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  // redirect
  const uploadEnds = (value) => {
    if (value != "") {
      setReload(!reload);
    }
    navigate("/profile");
    setIsLoading(false);
  };

  return (
    <div className="deliverstory">
      <div className="deliverstory_topnav">
        <div
          className="deliverstory_topnav_back"
          onClick={() => navigate("/profile")}>
          <ArrowBackIcon /> Zurück
        </div>
      </div>
      <div className="deliverstory_body">
        <div className="deliverstory_body_checklist">
          <div className="deliverstory_body_checklist_row">
            <div className="deliverstory_body_checklist_row_left">1.</div>
            <div className="deliverstory_body_checklist_row_right">
              <div
                className="deliverstory_body_checklist_row_right_btn"
                onClick={() => handleDownload()}>
                <div className="deliverstory_body_checklist_row_right_text_icon">
                  <DownloadIcon />
                </div>
                Video Speichern
              </div>
            </div>
          </div>
          <div className="deliverstory_body_checklist_row">
            <div className="deliverstory_body_checklist_row_left">2.</div>
            <div className="deliverstory_body_checklist_row_right">
              <div className="deliverstory_body_checklist_row_right_text">
                <div className="deliverstory_body_checklist_row_right_text_icon">
                  <InstagramIcon />
                </div>
                <div className="deliverstory_body_checklist_row_right_text_text">
                  Gehe zu Instagram und teile das Video als Story
                </div>
              </div>
            </div>
          </div>
          <div className="deliverstory_body_checklist_row">
            <div className="deliverstory_body_checklist_row_left">3.</div>
            <div className="deliverstory_body_checklist_row_right">
              <div className="deliverstory_body_checklist_row_right_text">
                <div className="deliverstory_body_checklist_row_right_text_icon">
                  <img
                    src="../../../sharebtn.svg"
                    className="deliverstory_body_checklist_row_right_text_img"
                  />{" "}
                </div>
                <div className="deliverstory_body_checklist_row_right_text_text">
                  Klicke auf Teilen
                </div>
              </div>
            </div>
          </div>
          <div className="deliverstory_body_checklist_row">
            <div className="deliverstory_body_checklist_row_left">4.</div>
            <div className="deliverstory_body_checklist_row_right">
              <div className="deliverstory_body_checklist_row_right_text">
                <div className="deliverstory_body_checklist_row_right_text_icon">
                  <img
                    src="../../../copylink.svg"
                    className="deliverstory_body_checklist_row_right_text_img"
                  />{" "}
                </div>
                <div className="deliverstory_body_checklist_row_right_text_text">
                  Klicke auf Link kopieren
                </div>
              </div>
            </div>
          </div>
          <div className="deliverstory_body_checklist_row">
            <div className="deliverstory_body_checklist_row_left">5.</div>
            <div className="deliverstory_body_checklist_row_right">
              <div className="deliverstory_body_checklist_row_right_text">
                <div className="deliverstory_body_checklist_row_right_text_icon">
                  <ArrowBottom />
                </div>
                <div className="deliverstory_body_checklist_row_right_text_text">
                  Füge den Text hier ein
                </div>
              </div>
            </div>
          </div>
          <input
            placeholder="Kopierten Link hier einfügen"
            className="deliverstory_body_checklist_row_right_input"
            value={storyLink}
            onChange={(e) => setStoryLink(e.target.value)}
          />
          {isLoading ? (
            <div className="deliverstory_body_form_cta">
              <Spinner />
            </div>
          ) : (
            <div
              className="deliverstory_body_form_cta"
              onClick={() => handleUpload()}>
              Fertig
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// einnahmen, leistungen, fortschritt, auftraggeber,

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const ArrowBottom = styled(MdContentPaste)`
  font-size: 18px;
  color: #474747;
`;

const DownloadIcon = styled(MdDownload)`
  font-size: 18px;
  color: #474747;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 18px;
  color: #474747;
`;

const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 23px;
  color: #474747;
`;
