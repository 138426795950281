import React, { useEffect } from "react";

const URLPreloadFromArray = ({ mediaURLArray }) => {
  useEffect(() => {
    // Iterate over each media URL and preload them
    mediaURLArray.forEach((url) => {
      if (
        url.endsWith(".mp4") ||
        url.endsWith(".webm") ||
        url.endsWith(".ogg")
      ) {
        // Video preload
        const video = document.createElement("video");
        video.src = url;
        video.preload = "auto"; // Set preload attribute to 'auto' to preload the video
        video.load(); // Start loading the video

        // Optional: Handle any additional events or actions during preloading
        video.addEventListener("loadeddata", () => {
          console.log(`Video ${url} loaded and ready`);
        });

        // Clean up on component unmount
        return () => {
          video.removeEventListener("loadeddata", () => {
            console.log(`Video ${url} unloaded`);
          });
        };
      } else if (
        url.endsWith(".jpg") ||
        url.endsWith(".jpeg") ||
        url.endsWith(".png") ||
        url.endsWith(".gif")
      ) {
        // Image preload
        const image = new Image();
        image.src = url;

        // Optional: Handle any additional events or actions during preloading
        image.onload = () => {
          console.log(`Image ${url} loaded and ready`);
        };

        // Clean up on component unmount
        return () => {
          image.onload = null;
        };
      } else if (
        url.endsWith(".mp3") ||
        url.endsWith(".wav") ||
        url.endsWith(".ogg")
      ) {
        // Audio preload
        const audio = new Audio();
        audio.src = url;
        audio.preload = "auto"; // Set preload attribute to 'auto' to preload the audio
        audio.load(); // Start loading the audio

        // Optional: Handle any additional events or actions during preloading
        audio.addEventListener("loadeddata", () => {
          console.log(`Audio ${url} loaded and ready`);
        });

        // Clean up on component unmount
        return () => {
          audio.removeEventListener("loadeddata", () => {
            console.log(`Audio ${url} unloaded`);
          });
        };
      }
    });
  }, [mediaURLArray]);

  return null; // or return a placeholder if needed
};

export default URLPreloadFromArray;
