import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdCampaign,
  MdClose,
  MdFavoriteBorder,
  MdOutlineCampaign,
  MdPersonOutline,
  MdSearch,
} from "react-icons/md";

import "../../styles/Bottombar.css";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { isEmpty } from "../../functions/isEmpty";
import { useLanguage } from "../../contexts/LanguageContext";
import texts from "../../texts/Texts";

export default function Bottombar({ isMobile }) {
  let navigate = useNavigate();
  const location = useLocation();
  const { language, switchLanguage } = useLanguage();

  return (
    <div className="bottombar">
      <div
        className={
          location.pathname == "/" ? "bottombar_left bold" : "bottombar_left"
        }
        onClick={() => navigate("/")}>
        Suche
      </div>
      <div
        className={
          location.pathname == "/content"
            ? "bottombar_middle bold"
            : "bottombar_middle"
        }
        onClick={() => navigate("/content")}>
        Content
      </div>
      <div
        className={
          location.pathname == "/profile"
            ? "bottombar_right bold"
            : "bottombar_right"
        }
        onClick={() => navigate("/profile")}>
        Profil
      </div>
    </div>
  );
}

const PersonIcon = styled(MdPersonOutline)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BackIcon = styled(MdArrowBackIos)`
  font-size: 27px;
  color: #f58700;
  margin-right: -15px;
`;

const CancelIcon = styled(MdClose)`
  font-size: 26px;
  color: #696969;
  margin-top: 3px;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 30px;
  margin-top: 5px;
  color: #b0b0b0;
`;

const FavoriteIcon = styled(MdFavoriteBorder)`
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 2px;
  color: #b0b0b0;
`;

const CampaignIcon = styled(MdOutlineCampaign)`
  font-size: 31px;
  margin-top: 4px;
  color: #b0b0b0;
`;

const ProfileIcon = styled(MdPersonOutline)`
  font-size: 27px;
  margin-top: 6px;
  color: #b0b0b0;
`;

/* ACTIVE */

const SearchIconActive = styled(MdSearch)`
  font-size: 31px;
  margin-top: 4px;
  color: #b0b0b0;
`;

const FavoriteIconActive = styled(MdFavoriteBorder)`
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 2px;
  color: #f58700;
`;

const CampaignIconActive = styled(MdOutlineCampaign)`
  font-size: 31px;
  margin-top: 4px;
  color: #f58700;
`;

const CampaignIconWhite = styled(MdCampaign)`
  font-size: 29px;
  margin-top: 2px;
  color: #ffffff;
`;

const ProfileIconActive = styled(MdPersonOutline)`
  font-size: 27px;
  margin-top: 6px;
  color: #f58700;
`;
