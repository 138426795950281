import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Settings.css";
import "../styles/Promotion.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";

export default function Settings({
  isMobile,
  apiURL,
  authToken,
  creators,
  services,
  destroySession,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  return (
    <div className="promotion">
      <div className="promotion_topnav">
        <div className="promotion_topnav_back" onClick={() => navigate("/")}>
          <ArrowBackIcon />
        </div>
        <div className="promotion_topnav_middle">Einstellungen</div>

        <div className="promotion_topnav_right"></div>
      </div>
      <div className="settings_list">
        <div className="settings_list_element" onClick={() => destroySession()}>
          Ausloggen
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 23px;
  color: #474747;
`;
