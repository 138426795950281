export const isEmpty = (obj) => {
  // Check for undefined, null, empty string, or false values
  if (obj === undefined || obj === null || obj === "") {
    return true;
  }

  // If it's an object, check for an empty object
  if (typeof obj === "object" && Object.keys(obj).length === 0) {
    return true;
  }

  return false;
};
