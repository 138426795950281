import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import Home from "./screens/Home";
import * as authentication from "./functions/authentication";
import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Orders from "./screens/Content";
import Profile from "./screens/Profile";
import ServiceEdit from "./screens/ServiceEdit";
import Influencer from "./screens/Influencer";
import Service from "./screens/Service";
import Create from "./screens/Create";
import Login from "./screens/Login";
import Content from "./screens/Content";
import Promotion from "./screens/Promotion";
import Fulfill from "./screens/Fulfill";
import Settings from "./screens/Settings";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.letsfluence.com";
  if (developerMode) {
    apiURL = "https://developercloud.letsfluence.com";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [shadowUserData, setShadowUserData] = useState([]);

  /* user specific Data */
  const [filteredContents, setFilteredContents] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  const [reload, setReload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // State to keep track of whether the video is muted
  const isMobile = useScreenType();

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setAuthToken();
      setCompanyData([]);
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
    setData: setCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: creators,
    loading: loadingCreatorData,
    error: errorCreatorData,
  } = useFetch(
    `${apiURL}/api/creators/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [reload] // Additional dependencies, if needed
  );

  const {
    data: services,
    loading: loadingServices,
    error: errorServices,
    setData: setServices,
  } = useFetch(
    `${apiURL}/api/services/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: templates,
    loading: loadingtemplates,
    error: errorTemplates,
    setData: setTemplates,
  } = useFetch(
    `${apiURL}/api/templates/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: contents,
    loading: loadingContents,
    error: errorContents,
  } = useFetch(
    `${apiURL}/api/content/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: orders,
    loading: loadingOrders,
    error: errorOrders,
  } = useFetch(
    `${apiURL}/api/orders/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  // setShadowUser Data
  useEffect(() => {
    const shadowUserID = getShadowUserIdFromLocalStorage();
    console.log("shadowUserID:", shadowUserID, "type:", typeof shadowUserID); // Debugging output

    if (isEmpty(shadowUserID)) {
      console.log("new db entry");
      putShadowUserDataForID();
    } else {
      console.log("fetch db entry");
      getShadowUserData(shadowUserID);
    }
  }, []);

  const putShadowUserDataForID = async () => {
    console.log("a");
    const resa = await fetch(`${apiURL}/api/shadowUser/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        userID: 0,
        status: 1,
      }),
    });

    await resa.text().then((value) => getShadowUserData(value));
  };

  const getShadowUserData = async (idval) => {
    console.log("newID = " + idval);
    setShadowUserIdToLocalStorage(idval);
    const resa = await fetch(`${apiURL}/api/shadowUser/index.php?id=` + idval, {
      method: "GET",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      //  body: JSON.stringify({ }),
    });

    await resa.text().then((value) => setShadowUserData(JSON.parse(value)));
  };

  // removeShadowUser Data
  useEffect(() => {
    if (
      !isEmpty(companyData) &&
      !isEmpty(shadowUserData) &&
      !isEmpty(contents)
    ) {
      // alle contents überprüfen ob ihre company_id == shadowUserData.id
      contents.map((cont) => {
        if (cont.company_id == shadowUserData[0].id) {
          changeShadowUserContentToRealUserContent(cont.id);
        }
      });
    }
  }, [companyData, shadowUserData]);

  const getShadowUserIdFromLocalStorage = () => {
    let shadowUser = localStorage.getItem("@shadowUserID");
    console.log("Retrieved from localStorage:", shadowUser);
    // Check if the returned value is the string "undefined"
    if (shadowUser === "undefined") {
      return null; // Treat it as null if it's the string "undefined"
    }
    return shadowUser; // Will return null if the key does not exist
  };
  const setShadowUserIdToLocalStorage = (idval) => {
    localStorage.setItem("@shadowUserID", idval);
  };

  const changeShadowUserContentToRealUserContent = async (content_id) => {
    const resa = await fetch(
      `${apiURL}/api/content/index.php?id=` + content_id,
      {
        method: "POST",
        headers: {
          //      Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          company_id: companyData[0].id,
        }),
      }
    );

    await resa.text().then((value) => setReload(!reload));
  };

  // filter Content for id
  useEffect(() => {
    let helpArr = [];
    // Filter for shadowUser content
    if (
      !isEmpty(shadowUserData) &&
      isEmpty(companyData) &&
      !isEmpty(contents)
    ) {
      console.log("shadowusew");
      contents.map((cont) => {
        if (cont.company_id == shadowUserData[0].id) {
          helpArr.push(cont);
          console.log(cont);
        }
      });
    } else if (!isEmpty(companyData) && !isEmpty(contents)) {
      contents.map((cont) => {
        if (cont.company_id == companyData[0].id) {
          helpArr.push(cont);
        }
      });
    }
    setFilteredContents(helpArr);
  }, [shadowUserData, companyData, contents]);

  useEffect(() => {
    let helpArr = [];
    // Filter for shadowUser content
    if (!isEmpty(companyData) && !isEmpty(services)) {
      services.map((service) => {
        if (service.company_id == companyData[0].id) {
          helpArr.push(service);
        }
      });
    }
    setFilteredServices(helpArr);
  }, [companyData, services]);

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={services}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />
            <Route
              path="/influencer/:id/service/:serviceid"
              element={
                <Service
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={services}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />
            <Route
              path="/profile"
              element={
                <Profile
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={filteredServices}
                  content={contents}
                  setReload={setReload}
                  reload={reload}
                  orders={orders}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />
            <Route
              path="/create"
              element={
                <Create
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={services}
                  setReload={setReload}
                  templates={templates}
                  reload={reload}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />
            <Route
              path="/promotion/:id"
              element={
                <Promotion
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={services}
                  setReload={setReload}
                  reload={reload}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  content={filteredContents}
                  orders={orders}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />
            <Route
              path="/create/:id"
              element={
                <Create
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={services}
                  templates={templates}
                  setReload={setReload}
                  reload={reload}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />

            <Route
              path="/content"
              element={
                <Content
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  content={filteredContents}
                  setReload={setReload}
                  reload={reload}
                  orders={orders}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />

            <Route
              path="/coop/:id"
              element={
                <Create
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={services}
                  setReload={setReload}
                  reload={reload}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />

            <Route
              path="/viewcoop/:id"
              element={
                <Create
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={services}
                  setReload={setReload}
                  templates={templates}
                  reload={reload}
                  content={filteredContents}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />

            <Route
              path="/fulfill/:id"
              element={
                <Fulfill
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  creators={creators}
                  services={services}
                  setReload={setReload}
                  templates={templates}
                  reload={reload}
                  content={filteredContents}
                  orders={orders}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />

            <Route
              path="/login"
              element={
                <Login
                  isMobile={isMobile}
                  authToken={authToken}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  signUp={signUp}
                  username={username}
                  setUsername={setUsername}
                  signIn={signIn}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />

            <Route
              path="/settings"
              element={
                <Settings
                  isMobile={isMobile}
                  authToken={authToken}
                  destroySession={destroySession}
                  companyData={
                    isEmpty(companyData) && !isEmpty(shadowUserData)
                      ? shadowUserData[0]
                      : companyData[0]
                  }
                />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
