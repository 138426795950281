import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";

export default function Home({
  isMobile,
  apiURL,
  authToken,
  creators,
  services,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  const getImgFileFromVideo = (videourl) => {
    // Create a new URL object from the provided video URL
    const url = new URL(videourl);

    // Get the filename from the URL (e.g., "abc123.mp4")
    const filename = url.pathname.split("/").pop(); // "abc123.mp4"

    // Extract the file name without the extension
    const fileWithoutExtension = filename.substring(
      0,
      filename.lastIndexOf(".")
    ); // "abc123"

    // Append '-img' to the file name and change the extension to '.jpg'
    const imageFilename = `${fileWithoutExtension}-img.jpg`; // "abc123-img.jpg"

    // Replace the original filename in the URL with the new image filename
    url.pathname = url.pathname.replace(filename, imageFilename);

    // Return the new image URL as a string
    return url.toString();
  };

  function formatEuroCents(eurocents) {
    // Convert string to number
    let totalCents = parseInt(eurocents, 10);

    // Get euros and cents
    let euros = Math.floor(totalCents / 100);
    let cents = totalCents % 100;

    // Pad the cents to ensure two digits
    let formattedCents = cents < 10 ? "0" + cents : cents;

    // Return the formatted string
    return `${euros},${formattedCents}`;
  }

  return (
    <div className="home">
      <div className="home_topnav">
        <div className="home_topnav_logo">
          <img src="../../lflogofin.svg" className="home_topnav_logo_img" />
        </div>

        <div
          className="home_topnav_right"
          onClick={() => navigate("/settings")}>
          <MenuIcon />
        </div>
      </div>
      <div className="home_body">
        <div className="home_body_list">
          {services.map((service) => (
            <div
              className="home_body_list_listelement"
              onClick={() => navigate("/coop/" + service.id)}>
              <div className="home_body_list_listelement_top">
                <img
                  key={JSON.parse(service.videoURLs)[0].url} // Add key prop here
                  controls={true}
                  className=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                  preload="metadata"
                  id={"video" + JSON.parse(service.videoURLs)[0].url}
                  src={getImgFileFromVideo(
                    JSON.parse(service.videoURLs)[0].url
                  )}
                />
              </div>
              {!isEmpty(service.price_storypost_eurocent) && (
                <div className="home_body_list_listelement_overlay">
                  ab
                  {" " +
                    formatEuroCents(service.price_storypost_eurocent) +
                    " €"}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Bottombar />
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
