import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Content.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";
import ContentListElement from "../components/navbars/ContentListElement";

export default function Content({
  isMobile,
  apiURL,
  authToken,
  creators,
  content,
  reload,
  setReload,
  orders,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  return (
    <div className="content">
      <div className="content_topnav">Content</div>
      <div className="content_body_list">
        {content.map((service) => (
          <ContentListElement
            data={service}
            key={service.id}
            apiURL={apiURL}
            authToken={authToken}
            reload={reload}
            setReload={setReload}
            orders={orders}
          />
        ))}
      </div>
      <Bottombar />
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const CheckIcon = styled(MdCheckCircle)`
  font-size: 18px;
  color: #474747;
`;

const CheckIconBlank = styled(MdCheckCircleOutline)`
  font-size: 18px;
  color: #474747;
`;
