import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdCampaign,
  MdClose,
  MdFavoriteBorder,
  MdOutlineCampaign,
  MdPersonOutline,
  MdSearch,
} from "react-icons/md";

import "../../styles/Bottombar.css";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { isEmpty } from "../../functions/isEmpty";
import { useLanguage } from "../../contexts/LanguageContext";
import texts from "../../texts/Texts";
import Spinner from "../CTA/Spinner";
import { data } from "@tensorflow/tfjs";

export default function ContentListElement({
  data,
  apiURL,
  authToken,
  reload,
  setReload,
  type,
  orders,
}) {
  let navigate = useNavigate();
  const location = useLocation();
  const [merged_video_url, setMerged_video_url] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeOrder, setActiveOrder] = useState({});
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [file, setFile] = useState(null); // Store the downloaded file for sharing

  useEffect(() => {
    if (!isEmpty(orders)) {
      orders.map((order) => {
        if (order.content_id == data.id) {
          setActiveOrder(order);
        }
      });
    }
  }, [orders]);

  useEffect(async () => {
    if (isEmpty(data.merged_video_url) && isLoading == false) {
      setIsLoading(true);
      let helpArr = [];
      JSON.parse(data.videoURLs).map((video) => {
        helpArr.push(video.url);
      });
      const resa = await fetch(`${apiURL}/api/images/mergeVideos.php`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          urls: helpArr,
        }),
      });

      await resa.text().then((value) => handleUpload(value));
    } else if (!isEmpty(data.merged_video_url)) {
      setMerged_video_url(data.merged_video_url);
    }
  }, [data]);

  const handleUpload = async (url) => {
    setMerged_video_url(url);
    const resa = await fetch(`${apiURL}/api/content/index.php?id=` + data.id, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        merged_video_url: url,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = () => {
    setIsLoading(false);
    setReload(!reload);
  };

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const handleDownload = async () => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", merged_video_url, true);
      xhr.responseType = "blob";

      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setDownloadProgress(percentComplete);
        }
      };

      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const file = new File([blob], "video.mp4", { type: "video/mp4" });
          setFile(file); // Store the file for later sharing

          // Automatically download for non-iOS or unsupported sharing
          if (!navigator.canShare || !navigator.canShare({ files: [file] })) {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "video.mp4";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      };

      xhr.onerror = function () {
        console.error("Error downloading the video.");
      };

      xhr.send();
    } catch (error) {
      console.error("Error sharing the file:", error);
    }
  };
  const getImgFileFromVideo = (videourl) => {
    // Create a new URL object from the provided video URL
    const url = new URL(videourl);

    // Get the filename from the URL (e.g., "abc123.mp4")
    const filename = url.pathname.split("/").pop(); // "abc123.mp4"

    // Extract the file name without the extension
    const fileWithoutExtension = filename.substring(
      0,
      filename.lastIndexOf(".")
    ); // "abc123"

    // Append '-img' to the file name and change the extension to '.jpg'
    const imageFilename = `${fileWithoutExtension}-img.jpg`; // "abc123-img.jpg"

    // Replace the original filename in the URL with the new image filename
    url.pathname = url.pathname.replace(filename, imageFilename);

    // Return the new image URL as a string
    return url.toString();
  };

  const promote = (id) => {
    navigate("/promotion/" + id);
  };

  const fulfill = (id) => {
    navigate("/fulfill/" + id);
  };

  function formatEuroCents(eurocents) {
    // Convert string to number
    let totalCents = parseInt(eurocents, 10);

    // Get euros and cents
    let euros = Math.floor(totalCents / 100);
    let cents = totalCents % 100;

    // Pad the cents to ensure two digits
    let formattedCents = cents < 10 ? "0" + cents : cents;

    // Return the formatted string
    return `${euros},${formattedCents}`;
  }

  const handleShare = async () => {
    if (
      file &&
      navigator.canShare &&
      navigator.canShare({ files: [file] }) &&
      isIOS()
    ) {
      try {
        await navigator.share({
          title: "Mein letsfluence Video",
          text: "Auf Insta teilen",
          files: [file],
        });
      } catch (error) {
        console.error("Error sharing the file:", error);
      }
    }
  };

  const openInsta = (url) => {
    window.open(url, "_blank");
  };

  if (type == "own") {
    return (
      <div className="content_body_list_listelement">
        <div
          className="content_body_list_listelement_left"
          onClick={() => navigate("/viewcoop/" + data.id)}>
          <img
            key={JSON.parse(data.videoURLs)[0].url} // Add key prop here
            controls={true}
            className="content_body_list_listelement_left_img"
            preload="metadata"
            id={"video" + JSON.parse(data.videoURLs)[0].url}
            src={getImgFileFromVideo(JSON.parse(data.videoURLs)[0].url)}
          />
        </div>
        <div className="content_body_list_listelement_right">
          {!isEmpty(activeOrder) ? (
            <>
              {isEmpty(activeOrder.delivery_storypost_url) ? (
                <div
                  className="content_body_list_listelement_right_button"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f58700",
                    fontWeight: "600",
                  }}
                  onClick={() => fulfill(activeOrder.id)}>
                  Promotion requested
                </div>
              ) : (
                <div
                  className="content_body_list_listelement_right_button"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#ffffff",
                    color: "#f58700",
                    fontWeight: "600",
                  }}
                  onClick={() => fulfill(activeOrder.id)}>
                  Promotion abgeschlossen
                </div>
              )}
            </>
          ) : (
            <div
              className="content_body_list_listelement_right_button"
              style={{ textAlign: "center" }}
              onClick={() => promote(data.id)}>
              Promotion noch nicht gestartet
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="content_body_list_listelement">
      <div
        className="content_body_list_listelement_left"
        onClick={() => navigate("/viewcoop/" + data.id)}>
        <img
          key={JSON.parse(data.videoURLs)[0].url} // Add key prop here
          controls={true}
          className="content_body_list_listelement_left_img"
          preload="metadata"
          id={"video" + JSON.parse(data.videoURLs)[0].url}
          src={getImgFileFromVideo(JSON.parse(data.videoURLs)[0].url)}
        />
      </div>
      <div className="content_body_list_listelement_right">
        {isEmpty(merged_video_url) ? (
          <div className="content_body_list_listelement_right_button">
            Video wird vorbereitet <Spinner />
          </div>
        ) : (
          <>
            {file && isIOS() ? (
              <div
                className="content_body_list_listelement_right_button"
                onClick={() => handleShare()}>
                Bereit zum Teilen
              </div>
            ) : (
              <div
                className="content_body_list_listelement_right_button"
                onClick={() => handleDownload()}>
                Download
                {downloadProgress > 0 && (
                  <progress
                    className="content_body_list_listelement_right_button_progress"
                    value={downloadProgress}
                    max="100"></progress>
                )}
              </div>
            )}
          </>
        )}

        {!isEmpty(activeOrder) ? (
          <>
            {isEmpty(activeOrder.delivery_storypost_url) ? (
              <div
                className="content_body_list_listelement_right_button"
                style={{
                  textAlign: "center",
                  backgroundColor: "#ffffff",
                  color: "#f58700",
                  fontWeight: "600",
                }}
                onClick={() => fulfill(activeOrder.id)}>
                Promotion requested
              </div>
            ) : (
              <div
                className="content_body_list_listelement_right_button"
                style={{
                  textAlign: "center",
                  backgroundColor: "#f58700",
                  fontWeight: "600",
                }}
                onClick={() => openInsta(activeOrder.delivery_storypost_url)}>
                Promotion live. Click to show
              </div>
            )}
          </>
        ) : (
          <>
            {!isEmpty(data.price_storypost_eurocent) ? (
              <div
                className="content_body_list_listelement_right_button"
                onClick={() => promote(data.id)}>
                Promote {formatEuroCents(data.price_storypost_eurocent) + " €"}
              </div>
            ) : (
              <div
                className="content_body_list_listelement_right_button"
                style={{ textAlign: "center" }}>
                Promotion für dieses Video nicht verfügbar
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const PersonIcon = styled(MdPersonOutline)`
  font-size: 26px;
  color: #8f8f8f;
`;

const BackIcon = styled(MdArrowBackIos)`
  font-size: 27px;
  color: #f58700;
  margin-right: -15px;
`;

const CancelIcon = styled(MdClose)`
  font-size: 26px;
  color: #696969;
  margin-top: 3px;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 30px;
  margin-top: 5px;
  color: #b0b0b0;
`;

const FavoriteIcon = styled(MdFavoriteBorder)`
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 2px;
  color: #b0b0b0;
`;

const CampaignIcon = styled(MdOutlineCampaign)`
  font-size: 31px;
  margin-top: 4px;
  color: #b0b0b0;
`;

const ProfileIcon = styled(MdPersonOutline)`
  font-size: 27px;
  margin-top: 6px;
  color: #b0b0b0;
`;

/* ACTIVE */

const SearchIconActive = styled(MdSearch)`
  font-size: 31px;
  margin-top: 4px;
  color: #b0b0b0;
`;

const FavoriteIconActive = styled(MdFavoriteBorder)`
  font-size: 26px;
  margin-top: 7px;
  margin-bottom: 2px;
  color: #f58700;
`;

const CampaignIconActive = styled(MdOutlineCampaign)`
  font-size: 31px;
  margin-top: 4px;
  color: #f58700;
`;

const CampaignIconWhite = styled(MdCampaign)`
  font-size: 29px;
  margin-top: 2px;
  color: #ffffff;
`;

const ProfileIconActive = styled(MdPersonOutline)`
  font-size: 27px;
  margin-top: 6px;
  color: #f58700;
`;
