import styled from "styled-components/macro";
import React, { useEffect, useRef, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForward,
  MdArrowForwardIos,
  MdAttachFile,
  MdBookmark,
  MdBookmarkBorder,
  MdDelete,
  MdDeleteOutline,
  MdDownload,
  MdEdit,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdPlayArrow,
  MdShoppingCart,
  MdUpload,
  MdUploadFile,
  MdVideocam,
} from "react-icons/md";

import "../styles/Create.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import RecordScreen from "../components/RecordScreen";
import Spinner from "../components/CTA/Spinner";

export default function Create({
  isMobile,
  apiURL,
  authToken,
  creators,
  services,
  reload,
  setReload,
  orders,
  content,
  templates,
  isMuted,
  setIsMuted,
  creatorData,
  companyData,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const { id, serviceid } = useParams();

  // Check if the path is "/coop/{id}"
  const [isCoopPath, setIsCoopPath] = useState(location.startsWith("/coop"));
  const isViewCoopPath = location.startsWith("/viewcoop");
  const isCreateEditPath = !isEmpty(id);
  const [showTemplates, setShowTemplates] = useState(false);

  const [title, setTitle] = useState("Neues Video");
  const [creator_id, setCreator_id] = useState("");
  const [price_storypost_eurocent, setPrice_storypost_eurocent] = useState("");
  const newVideoElement = { url: "" };
  const [videos, setVideos] = useState([newVideoElement, newVideoElement]);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [allVideosRecorded, setAllVideosRecorded] = useState(true);
  const [editable_video_index, setEditable_video_index] = useState(
    !isCoopPath &&
      !isViewCoopPath && [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ]
  );
  const [
    changeActiveVideoIndexToEditableVideo,
    setChangeActiveVideoIndexToEditableVideo,
  ] = useState(false);
  const [LocalVideoHasBeenRecorded, setLocalVideoHasBeenRecorded] =
    useState(false);
  const [activeTemplate, setActiveTemplate] = useState({});
  // check if its collabmode

  useEffect(() => {
    let newVideos = [];
    let helpArr = [...videos];
    // if id is set, then add the data to the state
    if (!isEmpty(id) && !isEmpty(services) && !isViewCoopPath) {
      services.map((service) => {
        if (service.id == id) {
          JSON.parse(service.videoURLs).map((videourl) => {
            newVideos.push({ url: videourl.url });
          });
          setTitle(service.title);
          setCreator_id(service.company_id);
          setEditable_video_index(JSON.parse(service.editable_video_index));
          setPrice_storypost_eurocent(service.price_storypost_eurocent);
        }
      });
      setVideos(newVideos);
    }

    if (!isEmpty(id) && !isEmpty(services) && isViewCoopPath) {
      content.map((service) => {
        if (service.id == id) {
          JSON.parse(service.videoURLs).map((videourl) => {
            newVideos.push({ url: videourl.url });
          });
          setTitle(service.title);
          setCreator_id(service.company_id);
          setEditable_video_index(JSON.parse(service.editable_video_index));
          setPrice_storypost_eurocent(service.price_storypost_eurocent);
        }
      });
      setVideos(newVideos);
    }
  }, [id, services, isViewCoopPath]);

  // außerdem brauch ich ein delete video button

  const addVideo = () => {
    let helpArr = [...videos];
    helpArr.push(newVideoElement);
    setVideos(helpArr);
  };

  const handleVideoObjectChange = (newObject, index) => {
    let helpArr = [...videos];
    helpArr[index] = newObject;
    setVideos(helpArr);
  };

  const handleUploadForImage = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const files = [...videos];

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      if (!isEmpty(files[i].localURL)) {
        formData.append("files[]", file.files);
      }
    }

    if (!isEmpty(videos)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    }
  };

  // eigentlich nur die lokalenurls schicken und wenn antwort vom server kommt, dann
  // über videos, und wann immer ein lokaler vorhanden ist, wird imgurl eingesetzt,
  // wenn nicht vorhanden, wird url von videos eingesetzt

  const handleUpload = async (imgURL) => {
    setIsLoading(true);

    let pics = splitByHttps(imgURL);

    if (isCoopPath) {
      let helpArr = [...videos];
      let helpArr2 = [];
      let addCountPic = 0;
      for (let i = 0; i < helpArr.length; i++) {
        if (isEmpty(helpArr[i].localURL)) {
          helpArr2.push(helpArr[i]);
        } else {
          helpArr2.push(pics[addCountPic]);
          addCountPic++;
        }
      }

      const resa = await fetch(`${apiURL}/api/content/index.php?id=` + id, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          videoURLS: JSON.stringify(helpArr2),
          title: title,
          service_id: id,
          creator_id: creator_id,
          editable_video_index: JSON.stringify(editable_video_index),
          company_id: companyData.id,

          merged_video_url: "",
          price_storypost_eurocent: price_storypost_eurocent,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));

      return;
    }

    if (isViewCoopPath) {
      let helpArr = [...videos];
      let helpArr2 = [];
      let addCountPic = 0;
      for (let i = 0; i < helpArr.length; i++) {
        if (isEmpty(helpArr[i].localURL)) {
          helpArr2.push(helpArr[i]);
        } else {
          helpArr2.push(pics[addCountPic]);
          addCountPic++;
        }
      }

      const resa = await fetch(`${apiURL}/api/content/index.php?id=` + id, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          videoURLS: JSON.stringify(helpArr2),
          editable_video_index: JSON.stringify(editable_video_index),
          company_id: companyData.id,
          merged_video_url: "",
          price_storypost_eurocent: price_storypost_eurocent,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));

      return;
    }

    if (!isEmpty(id)) {
      let helpArr = [...videos];
      let helpArr2 = [];
      let addCountPic = 0;
      for (let i = 0; i < helpArr.length; i++) {
        if (isEmpty(helpArr[i].localURL)) {
          helpArr2.push(helpArr[i]);
        } else {
          helpArr2.push(pics[i + addCountPic]);
          addCountPic++;
        }
      }

      const resa = await fetch(`${apiURL}/api/services/index.php?id=` + id, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          videoURLS: JSON.stringify(helpArr2),
          title: title,
          editable_video_index: JSON.stringify(editable_video_index),
          merged_video_url: "",
          price_storypost_eurocent: price_storypost_eurocent,
          company_id: companyData.id,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    } else {
      if (imgURL != "") {
        const resa = await fetch(`${apiURL}/api/services/index.php`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            title: title,
            videoURLS: JSON.stringify(pics),
            company_id: companyData.id,
            editable_video_index: JSON.stringify(editable_video_index),

            merged_video_url: "",
            price_storypost_eurocent: price_storypost_eurocent,
          }),
        });

        await resa.text().then((value) => uploadEnds(value));
      }
    }
  };

  function splitByHttps(inputString) {
    // Split the string by "https"
    const parts = inputString.split("https");

    // Initialize an array to store the resulting strings
    const result = [];

    // Iterate through the split parts
    for (let i = 0; i < parts.length; i++) {
      if (i === 0) {
        // The first part is before the first "https"
        if (parts[i] !== "") {
          result.push(parts[i]);
        }
      } else {
        // Reattach "https" to the subsequent parts
        result.push({ url: "https" + parts[i] });
      }
    }

    return result;
  }

  const uploadEnds = (value) => {
    if (value != "") {
      setReload(!reload);
    }
    setIsLoading(false);

    if (isCoopPath || isViewCoopPath) {
      navigate("/content");
    } else {
      navigate("/profile");
    }
  };

  const getImgFileFromVideo = (videourl) => {
    if (isEmpty(videourl)) {
      return;
    }
    // Create a new URL object from the provided video URL
    const url = new URL(videourl);

    // Get the filename from the URL (e.g., "abc123.mp4")
    const filename = url.pathname.split("/").pop(); // "abc123.mp4"

    // Extract the file name without the extension
    const fileWithoutExtension = filename.substring(
      0,
      filename.lastIndexOf(".")
    ); // "abc123"

    // Append '-img' to the file name and change the extension to '.jpg'
    const imageFilename = `${fileWithoutExtension}-img.jpg`; // "abc123-img.jpg"

    // Replace the original filename in the URL with the new image filename
    url.pathname = url.pathname.replace(filename, imageFilename);

    // Return the new image URL as a string
    return url.toString();
  };

  useEffect(() => {
    let record = true;
    let helpArr = [...videos];
    if (isEmpty(helpArr[1].localURL)) {
      record = false;
    }
    setAllVideosRecorded(record);
  }, [videos, id]);

  useEffect(() => {
    if (isEmpty(activeTemplate)) {
      if (changeActiveVideoIndexToEditableVideo && activeVideoIndex != 1) {
        setActiveVideoIndex(1);
        console.log("1");
      }
    }
  }, [changeActiveVideoIndexToEditableVideo, activeVideoIndex]);

  const nextVideo = () => {
    if (isEmpty(activeTemplate)) {
      if (!changeActiveVideoIndexToEditableVideo) {
        if (videos.length > activeVideoIndex + 1) {
          setActiveVideoIndex(activeVideoIndex + 1);
        } else if (videos.length == activeVideoIndex + 1) {
          setActiveVideoIndex(0);
        }
      }
    }
  };

  const loadTemplateData = (templateIndex) => {
    let newVideos = [];
    JSON.parse(templates[templateIndex].videoURLs).map((videourl) => {
      newVideos.push({ url: videourl.url });
    });
    setVideos(newVideos);

    setActiveTemplate(templates[templateIndex]);
    setShowTemplates(false);
  };

  return (
    <div className="create">
      <div className="create_topnav">
        {isCoopPath && (
          <div className="create_topnav_back" onClick={() => navigate("/")}>
            <ArrowBackIcon /> Zurück
          </div>
        )}

        {isViewCoopPath && (
          <div
            className="create_topnav_back"
            onClick={() => navigate("/content")}>
            <ArrowBackIcon /> Zurück
          </div>
        )}

        {!isCoopPath && !isViewCoopPath && (
          <div
            className="create_topnav_back"
            onClick={() => navigate("/profile")}>
            <ArrowBackIcon /> Zurück
          </div>
        )}

        <div className="">
          {isCoopPath || isViewCoopPath ? (
            title
          ) : (
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className=""
            />
          )}
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div>
            {allVideosRecorded ? (
              <div
                className="create_topnav_save"
                onClick={() => handleUploadForImage()}>
                Fertig
              </div>
            ) : (
              <div className="create_topnav_save_blurred">Fertig</div>
            )}
          </div>
        )}
      </div>
      <div className="create_body">
        {showTemplates ? (
          <div className="create_body_templates">
            {templates.map((temp, index) => (
              <div
                className="create_body_templates_template"
                onClick={() => loadTemplateData(index)}>
                {temp.title}
              </div>
            ))}
          </div>
        ) : (
          <div className="create_body_video">
            {videos.map((video, index) => (
              <>
                <RecordScreen
                  className="create_body_video_record"
                  indexNumber={index}
                  handleVideoObjectChange={handleVideoObjectChange}
                  activeVideoIndex={activeVideoIndex}
                  videourl={video.url}
                  on={
                    isCoopPath || isViewCoopPath || isCreateEditPath
                      ? false
                      : true
                  }
                  creatorMode={!isCoopPath}
                  LocalVideoHasBeenRecorded={LocalVideoHasBeenRecorded}
                  setLocalVideoHasBeenRecorded={setLocalVideoHasBeenRecorded}
                  editable_video_index={editable_video_index}
                  setEditable_video_index={setEditable_video_index}
                  changeActiveVideoIndexToEditableVideo={
                    changeActiveVideoIndexToEditableVideo
                  }
                  setChangeActiveVideoIndexToEditableVideo={
                    setChangeActiveVideoIndexToEditableVideo
                  }
                  nextVideo={nextVideo}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  price_storypost_eurocent={price_storypost_eurocent}
                  setPrice_storypost_eurocent={setPrice_storypost_eurocent}
                  activeTemplate={activeTemplate}
                  setIsCoopPath={setIsCoopPath}
                />
              </>
            ))}
          </div>
        )}

        <div className="create_body_bottombar">
          {videos.map((video, index) => (
            <div
              key={"bottombarel" + index}
              className={
                activeVideoIndex == index
                  ? "create_body_bottombar_element_active"
                  : "create_body_bottombar_element"
              }
              onClick={() => setActiveVideoIndex(index)}>
              <>
                {index >= 1 ? (
                  <div className="create_body_bottombar_element_img">
                    <VideoCamIcon />
                  </div>
                ) : (
                  <img
                    className="create_body_bottombar_element_img"
                    src={
                      (!isEmpty(video) && isEmpty(id)) ||
                      (!isEmpty(video.url) && getImgFileFromVideo(video.url))
                    }
                  />
                )}
              </>
            </div>
          ))}

          {!isCoopPath && !isViewCoopPath && (
            <>
              <div
                className="create_body_bottombar_addbutton"
                onClick={() => addVideo()}>
                +
              </div>
              <div
                className="create_body_bottombar_addbutton"
                onClick={() => setShowTemplates(!showTemplates)}>
                tem
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const NextIcon = styled(MdArrowForward)`
  font-size: 18px;
  color: #ffffff;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 23px;
  color: #222222;
`;

const PlayArrowIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #ffffff;
`;

const DeleteIcon = styled(MdDeleteOutline)`
  font-size: 26px;
  color: #ffffff;
`;

const UploadIcon = styled(MdAttachFile)`
  font-size: 26px;
  color: #ffffff;
`;

const DownloadIcon = styled(MdDownload)`
  font-size: 26px;
  color: #717171;
`;

const EditIcon = styled(MdEdit)`
  font-size: 26px;
  color: #717171;
`;

const VideoCamIcon = styled(MdVideocam)`
  font-size: 26px;
  color: #717171;
`;
