import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Profile.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";
import ContentListElement from "../components/navbars/ContentListElement";

export default function Profile({
  isMobile,
  apiURL,
  authToken,
  creators,
  templates,
  services,
  content,
  reload,
  setReload,
  orders,
  companyData,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  const getImgFileFromVideo = (videourl) => {
    if (isEmpty(videourl)) {
      return;
    }
    // Create a new URL object from the provided video URL
    const url = new URL(videourl);

    // Get the filename from the URL (e.g., "abc123.mp4")
    const filename = url.pathname.split("/").pop(); // "abc123.mp4"

    // Extract the file name without the extension
    const fileWithoutExtension = filename.substring(
      0,
      filename.lastIndexOf(".")
    ); // "abc123"

    // Append '-img' to the file name and change the extension to '.jpg'
    const imageFilename = `${fileWithoutExtension}-img.jpg`; // "abc123-img.jpg"

    // Replace the original filename in the URL with the new image filename
    url.pathname = url.pathname.replace(filename, imageFilename);

    // Return the new image URL as a string
    return url.toString();
  };

  const getContentToThisService = (service) => {
    let helpArr = [];
    if (isEmpty(service)) {
      return [];
    } else {
      content.map((cont) => {
        if (cont.service_id == service.id) {
          helpArr.push(cont);
        }
      });
      return helpArr;
    }
  };

  return (
    <div className="profile">
      <div className="profile_topnav">
        <div className="profile_topnav_left">
          <div className="profile_body_bio">
            <div className="profile_body_bio_left">
              <img
                src={
                  isEmpty(companyData)
                    ? "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg"
                    : JSON.parse(companyData.pictures)[0].url
                }
                className="profile_body_bio_left_img"
              />
            </div>
            <div className="profile_body_bio_right">
              {isEmpty(companyData) ? "John Doe" : companyData.companyname}
            </div>
          </div>
        </div>

        <div
          className="profile_topnav_right"
          onClick={() => navigate("/create/")}>
          <div className="profile_topnav_right_button">+</div>
        </div>
      </div>
      <div className="profile_body">
        <div className="profile_body_services">
          {services.map((service) => (
            <div className="profile_body_listelement">
              <div
                className="profile_body_listelement_top"
                onClick={() => navigate("/create/" + service.id)}>
                <div className="profile_body_listelement_top_left">
                  <img
                    key={JSON.parse(service.videoURLs)[0].url} // Add key prop here
                    controls={true}
                    className="profile_body_listelement_top_left_img"
                    preload="metadata"
                    id={"video" + JSON.parse(service.videoURLs)[0].url}
                    src={getImgFileFromVideo(
                      JSON.parse(service.videoURLs)[0].url
                    )}
                  />
                </div>
                <div className="profile_body_listelement_top_right">
                  {service.title}
                </div>
              </div>
              <div className="profile_body_listelement_bottom">
                {getContentToThisService(service).map((cont) => {
                  return (
                    <>
                      {isEmpty(cont) ? (
                        "Noch keine Collabos zu diesem Video"
                      ) : (
                        <ContentListElement
                          type="own"
                          data={cont}
                          key={cont.id}
                          apiURL={apiURL}
                          authToken={authToken}
                          reload={reload}
                          orders={orders}
                          setReload={setReload}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Bottombar />
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

/*
In Profil kommt der Creator. dort nimmt man x videos auf.
jedes zweite video ist editierbar

datenstruktur: video 1, video 2, video 3 bis 10

*/
