import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Camera from "./Camera";
import { isEmpty } from "../functions/isEmpty";
import {
  MdOutlineVolumeMute,
  MdPause,
  MdPlayArrow,
  MdVolumeOff,
  MdVolumeUp,
} from "react-icons/md";
import styled from "styled-components/macro";

export default function RecordScreen({
  className,
  indexNumber,
  handleVideoObjectChange,
  activeVideoIndex,
  videourl,
  on,
  creatorMode,
  editable_video_index,
  setEditable_video_index,
  setChangeActiveVideoIndexToEditableVideo,
  changeActiveVideoIndexToEditableVideo,
  nextVideo,
  localVideoHasBeenRecorded,
  setLocalVideoHasBeenRecorded,
  isMuted,
  setIsMuted,
  price_storypost_eurocent,
  setPrice_storypost_eurocent,
  activeTemplate,
}) {
  const { id, serviceid } = useParams();
  const [recordStreams, setRecordStreams] = useState([]);
  const [cameraActive, setCameraActive] = useState(on);
  const [url, setURL] = useState("");
  const [photo, setPhoto] = useState();
  const [imageUploads, setImageUploads] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false); // State to track if the video is playing
  const videoRef = useRef(null); // Ref for the video element
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  /*
  useEffect(async () => {
    // am besten ich mach hier ein useEffect, der dann feuert, wenn activeTemplate gesetzt ist.
    if (!isEmpty(activeTemplate)) {
      try {
        let url = videourl;

        // Fetch the file from the provided URL
        const response = await fetch(url);
        // Convert the response to a blob
        const blob = await response.blob();
        // Get the file type
        const fileType = blob.type;
        // Create a file from the blob
        const file = new File([blob], "filename.jpg", { type: fileType });
        // Create a local URL for the file
        const localURL = URL.createObjectURL(file);

        // Set the file details in the state
        setPhoto({
          localURL: localURL,
          file: file,
          fileType: fileType,
        });
    }
    // ich hol mir nach index die daten
    // wenn ich der letzte index box bin, dann turn ich den state wieder aus
  }, [activeTemplate]);
  */

  const onPhotoTaken = (photoURL) => {
    if (!isEmpty(photoURL)) {
      setChangeActiveVideoIndexToEditableVideo(false);
      console.log("OnPhotoTaken");
      setPhoto(photoURL);
    }
  };

  useEffect(() => {
    if (!isEmpty(videourl)) {
      setURL(videourl);
      setCameraActive(false);
      stopAllStreams();
    }
  }, [videourl]);

  useEffect(() => {
    if (!isEmpty(photo)) {
      setChangeActiveVideoIndexToEditableVideo(false);
      setURL(photo.localURL);
      setCameraActive(false);
      stopAllStreams();
      handleVideoObjectChange(photo, indexNumber);
      setLocalVideoHasBeenRecorded(true);
    }

    return () => stopAllStreams();
  }, [photo]);

  const stopAllStreams = () => {
    recordStreams.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });
    setRecordStreams([]);
  };

  const resetCamera = () => {
    console.log("AAAA");
    setCameraActive(true);
    stopAllStreams();
    setURL("");
    setLocalVideoHasBeenRecorded(false);
  };

  const startRecording = () => {
    if (indexNumber != 1) {
      setChangeActiveVideoIndexToEditableVideo(true);
    } else {
      console.log("bbbb");

      setCameraActive(true);
      stopAllStreams();
      setURL("");
      setChangeActiveVideoIndexToEditableVideo(true);
      setLocalVideoHasBeenRecorded(false);
      console.log("2");
    }
  };

  // Das PRoblem ist, dass ich globalen reset mache für den kamera invoke.
  // was aktiviert die kamera? !cameraActive
  // der useeffect ist nicht das problem
  // sondern was anderes
  // das "error accessing camera" wenn ich woanders bin ist das problem
  useEffect(() => {
    if (
      changeActiveVideoIndexToEditableVideo &&
      indexNumber == 1 &&
      !isEmpty(activeTemplate)
    ) {
      console.log(changeActiveVideoIndexToEditableVideo);
      setLocalVideoHasBeenRecorded(false);

      setChangeActiveVideoIndexToEditableVideo(false);
      console.log("CCCC");

      setCameraActive(true);
      stopAllStreams();
      setURL("");
    }
  }, [changeActiveVideoIndexToEditableVideo, indexNumber]);

  const handleFileUpload = () => {
    let inputEl = document.getElementById("imgInp");
    if (!isEmpty(inputEl)) {
      inputEl.click();
    }
  };

  const insertLocalImage = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let newArray = [];
      imageUploads.map((imgObj) => newArray.push(imgObj));

      const localURL = URL.createObjectURL(file);
      const filetype = file.type;
      newArray.push({
        localURL: localURL,
        files: file,
        filetype: filetype,
      });
      setImageUploads(newArray);
      setPhoto({
        localURL: localURL,
        files: file,
        filetype: filetype,
      });
    } else {
      return;
    }

    return;
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle play state
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted; // Toggle the muted property
      setIsMuted(videoRef.current.muted); // Update the state to reflect the current mute status
    }
  };

  const StartPlaying = (e) => {
    e.target.play();
    setIsPlaying(true);
  };

  const handleVideoLoadedData = (e) => {
    setIsVideoLoaded(true);
    StartPlaying(e); // Your existing function
  };

  useEffect(() => {
    // Autoplay the video if it's the active one and muted
    if (videoRef.current && activeVideoIndex === indexNumber && isVideoLoaded) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to autoplay video:", error);
        // Handle any autoplay issues (e.g., prompt user or log for debugging)
      });
    }
  }, [activeVideoIndex, isVideoLoaded]);

  const toggleCollabo = () => {
    if (isEmpty(price_storypost_eurocent)) {
      setPrice_storypost_eurocent("5000");
    } else {
      setPrice_storypost_eurocent("");
    }
  };

  const toggleThisVideoToEditableIndex = (addRemove) => {
    let arr = [...editable_video_index];
    if (addRemove == "add") {
      arr.push(indexNumber);
    } else {
      const index = arr.indexOf(indexNumber);
      arr.splice(index, 1);
    }
    setEditable_video_index(arr);
  };

  return (
    <div>
      {activeVideoIndex !== indexNumber ? (
        <div></div>
      ) : (
        <div>
          {creatorMode &&
            !cameraActive &&
            !isEmpty(editable_video_index) &&
            editable_video_index.includes(indexNumber) && (
              <div
                className="editservice_body_resetbtn"
                onClick={() => resetCamera()}>
                reset
              </div>
            )}

          {!creatorMode && !cameraActive && (
            <>
              {!localVideoHasBeenRecorded ? (
                <div
                  className="editservice_body_startrecording"
                  onClick={() => startRecording()}>
                  Aufnahme Starten
                </div>
              ) : (
                <div
                  className="editservice_body_editrecording"
                  onClick={() => startRecording()}>
                  Neu Aufnehmen
                </div>
              )}
            </>
          )}

          {creatorMode && !cameraActive && (
            <>
              <div className="editservice_body_influencervideo">
                <div
                  className={
                    isEmpty(price_storypost_eurocent)
                      ? "editservice_body_influencervideo_toggleboxinactive"
                      : "editservice_body_influencervideo_toggleboxactive"
                  }
                  onClick={() => toggleCollabo()}></div>

                <div className="editservice_body_influencervideo_inputbox">
                  {!isEmpty(price_storypost_eurocent) ? (
                    <input
                      placheholder="Dein Verdienst pro Insta Story"
                      value={price_storypost_eurocent}
                      onChange={(e) =>
                        setPrice_storypost_eurocent(e.target.value)
                      }
                    />
                  ) : (
                    <div className="" onClick={() => toggleCollabo()}>
                      Bezahlte Werbepartnerschaft anbieten
                    </div>
                  )}
                </div>
              </div>

              {editable_video_index.includes(indexNumber) ? (
                <div
                  className="editservice_body_editablebtn"
                  onClick={() => toggleThisVideoToEditableIndex("remove")}>
                  jo
                </div>
              ) : (
                <div
                  className="editservice_body_editablebtn"
                  onClick={() => toggleThisVideoToEditableIndex("add")}>
                  no
                </div>
              )}
            </>
          )}

          <video
            key={`videokey${indexNumber + url}`}
            ref={videoRef} // Use ref to control the video element
            controls={false}
            className="editservice_body_videopresenter"
            preload="metadata"
            id={`videoid${indexNumber}`}
            autoPlay={true}
            onLoadedData={handleVideoLoadedData}
            playsInline={true}
            loop={!creatorMode ? false : true}
            muted={isMuted}
            onEnded={!creatorMode && isEmpty(activeTemplate) && nextVideo}
            style={{
              backgroundColor: "black",
              display:
                activeVideoIndex === indexNumber && !isEmpty(url)
                  ? "block"
                  : "none",
            }}>
            <source key={`srckey${indexNumber}`} src={url} type={`video/mp4`} />
            Your browser does not support the video tag.
          </video>
          <div
            className="editservice_body_playPauseButton"
            onClick={toggleMute}>
            {isMuted ? <UnMuteIcon /> : <MuteIcon />}
          </div>
          <input
            type="file"
            accept=".mp4, .mov"
            id={"imgInp"}
            name="files[]"
            className="createService_inputBtn d-none"
            onChange={(e) => insertLocalImage(e)}
          />
          <Camera
            onVideoRecorded={onPhotoTaken}
            className={"editservice_body_videorecorder"}
            key={`cam${indexNumber}`}
            turnOffCam={!cameraActive}
            recordStreams={recordStreams}
            setRecordStreams={setRecordStreams}
            overlayImageSrc="https://www.developercloud.letsfluence.com/api/images/placeholder/transparent.png"
            handleFileUpload={handleFileUpload}
            instructionText={activeTemplate.description}
            indexNumber={indexNumber}
          />
        </div>
      )}
    </div>
  );
}

const PlayIcon = styled(MdPlayArrow)`
  font-size: 30px;
  color: #ffffff;
`;
const PauseIcon = styled(MdPause)`
  font-size: 30px;
  color: #ffffff;
`;

const MuteIcon = styled(MdVolumeUp)`
  font-size: 30px;
  color: #ffffff;
`;

const UnMuteIcon = styled(MdVolumeOff)`
  font-size: 30px;
  color: #ffffff;
`;
