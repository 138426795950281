import styled from "styled-components/macro";
import React, { useEffect, useRef, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForward,
  MdArrowForwardIos,
  MdAttachFile,
  MdBookmark,
  MdBookmarkBorder,
  MdDelete,
  MdDeleteOutline,
  MdDownload,
  MdEdit,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdPlayArrow,
  MdShoppingCart,
  MdUpload,
  MdUploadFile,
} from "react-icons/md";

import "../styles/EditService.css";
import "../styles/Service.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import VideoRecorder from "../components/VideoRecorder";
import Bottombar from "../components/navbars/Bottombar";
import Camera from "../components/Camera";
import MediaPlayer from "../components/mediaPlayer/MediaPlayer";
import URLPreloadFromArray from "../components/URLPreloadFromArray";

export default function Create({
  isMobile,
  apiURL,
  authToken,
  creators,
  templates,
  services,
  reload,
  setReload,
  orders,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const { id, serviceid } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [recordStreams, setRecordStreams] = useState([]);
  const [cameraActive, setCameraActive] = useState(true);
  const [price, setPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeService, setActiveService] = useState({ title: "", video: "" });
  const [url, setURL] = useState("");
  const [editedpromptertext, setEditedpromptertext] = useState("");
  const [photo, setPhoto] = useState();
  const [activeVideo, setActiveVideo] = useState("");
  const [mergedURL, setMergedURL] = useState("");
  const [orderPageActive, setOrderPageActive] = useState(false);

  const creatorVideos = [
    "https://www.developercloud.letsfluence.com/api/images/uploads/09d24d91e2827ba305a2acba564bd32f.mp4",
    "https://www.developercloud.letsfluence.com/api/images/uploads/359253b82145657c998f8c5c08efdbb8.mp4",
  ];

  useEffect(() => {
    // Preload the videos
    creatorVideos.forEach((video) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = video;
      link.as = "video";
      document.head.appendChild(link);
    });
  }, [creatorVideos]);

  const nextVideo = () => {
    if (activeStep == 0) {
      setActiveVideo(url);
      setActiveStep(1);
      var video = document.getElementById("video" + url);
      video.play();
    }
    if (activeStep == 1) {
      setActiveVideo(creatorVideos[1]);
      setActiveStep(2);
      var video = document.getElementById("video" + creatorVideos[1]);
      video.play();
    }

    if (activeStep >= 2) {
      setActiveVideo(creatorVideos[0]);
      setActiveStep(0);
      var video = document.getElementById("video" + creatorVideos[0]);
      video.play();
    }
  };

  useEffect(() => {
    if (!isEmpty(templates)) {
      templates.map((order) => {
        if (order.id == serviceid) {
          setActiveService(order);
          setEditedpromptertext(replaceCompanyName(order.promptertext, order));
        }
      });
    }
  }, [serviceid, templates]);

  function replaceCompanyName(inputString, object) {
    // Check if the inputString contains "**companyname**"
    let newString = inputString;

    if (newString.includes("**companyname**")) {
      // Replace all occurrences of "**companyname**" with the value of activeService.input_companyname
      newString = newString.replace(
        /\*\*companyname\*\*/g,
        object.input_companyname
      );
    }
    if (newString.includes("**services**")) {
      // Replace all occurrences of "**companyname**" with the value of activeService.input_companyname
      newString = newString.replace(/\*\*services\*\*/g, object.input_services);
    }
    if (newString.includes("**position**")) {
      // Replace all occurrences of "**companyname**" with the value of activeService.input_companyname
      newString = newString.replace(
        /\*\*position\*\*/g,
        object.input_hiringposition
      );
    }
    // If "**companyname**" is not found, return the original inputString
    return newString;
  }

  const onPhotoTaken = (photoURL) => {
    if (!isEmpty(photoURL)) {
      setPhoto(photoURL);
    }
  };

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setCameraActive(true);
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraActive(false);
        alert(
          "Error accessing the camera. Please ensure your browser settings allow camera access and the website is served over HTTPS."
        );
      });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (!isEmpty(photo)) {
      setURL(photo.localURL);
      setActiveVideo(creatorVideos[0]);
      setActiveStep(0);
      setCameraActive(false);
      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      setRecordStreams([]);
    }

    return () => {
      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      setRecordStreams([]);
    };
  }, [photo]);

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const downloadVideo = async () => {
    try {
      // Add a cache-busting query parameter to the URL
      const cacheBustedUrl = `${mergedURL}?timestamp=${new Date().getTime()}`;

      // Generate a random number for the filename
      const randomNumber = Math.floor(Math.random() * 1e10);

      // Fetch the video file
      const response = await fetch(cacheBustedUrl);

      // Check if the response is ok
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Get the blob from the response
      const blob = await response.blob();

      // Create a File object
      const file = new File([blob], `video_${randomNumber}.mp4`, {
        type: "video/mp4",
      });

      if (
        navigator.canShare &&
        navigator.canShare({ files: [file] }) &&
        isIOS()
      ) {
        // Share the file if possible
        await navigator.share({
          title: `Mein letsfluence Video ${randomNumber}`,
          text: "Meine Kooperation erstellt auf letsfluence.com",
          files: [file],
        });
      } else {
        // Otherwise, download the file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `video_${randomNumber}.mp4`;

        // Append the link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the object URL
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.error("Error sharing or downloading the file:", error);
    }
  };

  useEffect(() => {
    if (!isEmpty(url)) {
      handleUploadForImage();
    }
  }, [url]);

  const mergeFiles = async (newURL) => {
    let helpArr = [creatorVideos[0], newURL, creatorVideos[1]];
    const resa = await fetch(`${apiURL}/api/images/mergeVideos.php`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken?.JWT}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        urls: helpArr,
      }),
    });

    await resa.text().then((value) => setMergedURL(value));
  };

  // upload image file
  const handleUploadForImage = async () => {
    setIsLoading(true);
    const formData = new FormData();

    if (!isEmpty(photo)) {
      formData.append("files[]", photo.files); // Ensure the photo is appended with the correct key
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => mergeFiles(value));
    }
  };

  // upload profile data
  const handleUpload = async (imgURL) => {
    setIsLoading(true);

    if (imgURL != "") {
      const resa = await fetch(`${apiURL}/api/services/index.php`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          title: activeService.title,
          price: price,
          videoURL: imgURL,
          promptertext: activeService.promptertext,
          input_companyname: activeService.input_companyname,
          input_logoURL: activeService.input_logoURL,
          input_services: activeService.input_services,
          input_hiringposition: activeService.input_hiringposition,
          company_id: 1,
          template_id: serviceid,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    }
  };

  // redirect
  const uploadEnds = (value) => {
    if (value != "") {
      setReload(!reload);
    }
    navigate("/profile");
    setIsLoading(false);
  };

  const resetCamera = () => {
    setURL("");
    setCameraActive(true);
    setRecordStreams([]);
    setMergedURL("");
  };

  const setVideo = (index) => {
    if (index == 0) {
      setActiveVideo(creatorVideos[0]);
      setActiveStep(0);
    }
    if (index == 1) {
      setActiveVideo(url);
      setActiveStep(1);
    }
    if (index >= 2) {
      setActiveVideo(creatorVideos[1]);
      setActiveStep(2);
    }
  };

  if (orderPageActive) {
    return (
      <div className="editservice">
        <div className="editservice_topnav">
          <div
            className="editservice_topnav_back"
            onClick={() => setOrderPageActive(false)}>
            <ArrowBackIcon /> Zurück
          </div>
        </div>
        <div className="editservice_body">
          <div className="service_body">
            <div className="service_body_boxes">
              <div className="service_body_boxes_box">Instagram Story</div>
              <div className="service_body_boxes_box">Instagram Reels</div>
              <div className="service_body_boxes_box">Tiktok Story</div>
              <div className="service_body_boxes_box">Tiktok Reels</div>
            </div>
          </div>
          <div className="service_body_buy">Kaufen</div>
        </div>
      </div>
    );
  }

  return (
    <div className="editservice">
      <URLPreloadFromArray mediaURLArray={[mergedURL]} />

      <URLPreloadFromArray mediaURLArray={creatorVideos} />
      <div className="editservice_topnav">
        <div
          className="editservice_topnav_back"
          onClick={() => navigate("/influencer/" + id)}>
          <ArrowBackIcon /> Zurück
        </div>
        {!isEmpty(url) && (
          <div
            className="editservice_topnav_promote"
            onClick={() => setOrderPageActive(true)}>
            Bewerben
          </div>
        )}
      </div>
      <div className="editservice_body">
        {!isEmpty(url) ? (
          <div>
            <div onClick={() => nextVideo()}>
              <video
                key={0} // Add key prop here
                controls={false}
                className="editservice_body_videorecorder"
                preload="metadata"
                id={"video" + creatorVideos[0]}
                autoPlay={true}
                playsInline={true}
                muted={false}
                onEnded={() => nextVideo()}
                style={{ display: 0 === activeStep ? "block" : "none" }}>
                <source
                  key={creatorVideos[0] + "src"}
                  src={creatorVideos[0]}
                  type={`video/mp4`}
                />
                Your browser does not support the video tag.
              </video>
              <video
                key={1} // Add key prop here
                controls={false}
                className="editservice_body_videorecorder"
                preload="metadata"
                id={"video" + url}
                autoPlay={false}
                playsInline={true}
                muted={false}
                onEnded={() => nextVideo()}
                style={{ display: 1 === activeStep ? "block" : "none" }}>
                <source key={url + "src"} src={url} type={`video/mp4`} />
                Your browser does not support the video tag.
              </video>
              <video
                key={2} // Add key prop here
                controls={false}
                className="editservice_body_videorecorder"
                preload="metadata"
                id={"video" + creatorVideos[1]}
                autoPlay={false}
                playsInline={true}
                muted={false}
                onEnded={() => nextVideo()}
                style={{ display: 2 === activeStep ? "block" : "none" }}>
                <source
                  key={creatorVideos[1] + "src"}
                  src={creatorVideos[1]}
                  type={`video/mp4`}
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="editservice_videosrow">
              <div
                onClick={() => setVideo(0)}
                className={
                  activeStep == 0
                    ? "editservice_videosrow_videoactive"
                    : "editservice_videosrow_video"
                }>
                1
              </div>
              <div
                onClick={() => setVideo(1)}
                className={
                  activeStep == 1
                    ? "editservice_videosrow_videoactive"
                    : "editservice_videosrow_video"
                }>
                2
              </div>
              <div
                onClick={() => setVideo(2)}
                className={
                  activeStep == 2
                    ? "editservice_videosrow_videoactive"
                    : "editservice_videosrow_video"
                }>
                3
              </div>
            </div>
            {!isEmpty(mergedURL) && (
              <div
                className="editservice_downloadbutton"
                onClick={() => downloadVideo()}>
                <DownloadIcon />
              </div>
            )}

            <div
              className="editservice_editbutton"
              onClick={() => resetCamera()}>
              <EditIcon />
            </div>
          </div>
        ) : (
          <></>
        )}

        {cameraActive && (
          <div className="editservice_body_description">
            {editedpromptertext}
          </div>
        )}

        <Camera
          onVideoRecorded={onPhotoTaken}
          turnOffCam={!cameraActive}
          recordStreams={recordStreams}
          setRecordStreams={setRecordStreams}
          overlayImageSrc={
            isEmpty(activeService.input_logoURL)
              ? ""
              : activeService.input_logoURL
          }
        />

        <div className="editservice_body_navbar">
          {isLoading ? (
            <div className="">...Loading</div>
          ) : (
            <div className="" onClick={() => handleUploadForImage()}>
              Save
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const NextIcon = styled(MdArrowForward)`
  font-size: 18px;
  color: #ffffff;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 23px;
  color: #222222;
`;

const PlayArrowIcon = styled(MdPlayArrow)`
  font-size: 33px;
  color: #ffffff;
`;

const DeleteIcon = styled(MdDeleteOutline)`
  font-size: 26px;
  color: #ffffff;
`;

const UploadIcon = styled(MdAttachFile)`
  font-size: 26px;
  color: #ffffff;
`;

const DownloadIcon = styled(MdDownload)`
  font-size: 26px;
  color: #717171;
`;

const EditIcon = styled(MdEdit)`
  font-size: 26px;
  color: #717171;
`;
