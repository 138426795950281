import styled from "styled-components/macro";
import React, { useEffect, useRef, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdContentPaste,
  MdDownload,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";
import { FaInstagram } from "react-icons/fa";

import "../styles/Promotion.css";
import "../styles/Bottombar.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate, useParams } from "react-router";
import Spinner from "../components/CTA/Spinner";

export default function Promotion({
  isMobile,
  apiURL,
  authToken,
  content,
  reload,
  setReload,
  creators,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeContent, setActiveContent] = useState({});
  const [activeCreator, setActiveCreator] = useState({});

  const [formFilled, setFormIsFilled] = useState(false);
  const [cvc, setCvc] = useState("");

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  useEffect(() => {
    if (!isEmpty(content)) {
      content.map((cont) => {
        if (cont.id == id) {
          setActiveContent(cont);
        }
      });
    }
  }, [content]);

  useEffect(() => {
    let filled = false;
    if (!isEmpty(cvc)) {
      filled = true;
    }
    setFormIsFilled(filled);
  }, [cvc]);

  useEffect(() => {
    if (!isEmpty(creators)) {
      creators.map((creator) => {
        if (creator.id == activeContent.creator_id) {
          setActiveCreator(creator);
        }
      });
    }
  }, [creators]);

  // upload profile data
  const handleUpload = async () => {
    setIsLoading(true);

    const resa = await fetch(`${apiURL}/api/orders/index.php`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        content_id: activeContent.id,
        service_id: activeContent.service_id,
        seller_id: activeContent.creator_id,
        buyer_id: 1,
        merged_video_url: activeContent.merged_video_url,
        price_storypost_eurocent: activeContent.price_storypost_eurocent,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  // redirect
  const uploadEnds = (value) => {
    if (value != "") {
      setReload(!reload);
    }
    navigate("/content");
    setIsLoading(false);
  };

  const getImgFileFromVideo = (videourl) => {
    // Create a new URL object from the provided video URL
    const url = new URL(videourl);

    // Get the filename from the URL (e.g., "abc123.mp4")
    const filename = url.pathname.split("/").pop(); // "abc123.mp4"

    // Extract the file name without the extension
    const fileWithoutExtension = filename.substring(
      0,
      filename.lastIndexOf(".")
    ); // "abc123"

    // Append '-img' to the file name and change the extension to '.jpg'
    const imageFilename = `${fileWithoutExtension}-img.jpg`; // "abc123-img.jpg"

    // Replace the original filename in the URL with the new image filename
    url.pathname = url.pathname.replace(filename, imageFilename);

    // Return the new image URL as a string
    return url.toString();
  };

  function formatEuroCents(eurocents) {
    // Convert string to number
    let totalCents = parseInt(eurocents, 10);

    // Get euros and cents
    let euros = Math.floor(totalCents / 100);
    let cents = totalCents % 100;

    // Pad the cents to ensure two digits
    let formattedCents = cents < 10 ? "0" + cents : cents;

    // Return the formatted string
    return `${euros},${formattedCents}`;
  }

  return (
    <div className="promotion">
      <div className="promotion_topnav">
        <div
          className="promotion_topnav_back"
          onClick={() => navigate("/content")}>
          <ArrowBackIcon />
        </div>
        <div className="promotion_topnav_middle">Influencer Promotion</div>

        <div className="promotion_topnav_right"></div>
      </div>
      <div className="promotion_body">
        <div className="promotion_body_section">
          <div className="promotion_body_section_title">Influencer Service</div>
          <div className="promotion_body_section_row">Beworbenes Video</div>
          <div className="promotion_body_section_row">
            <div className="promotion_body_section_row_leftImageBox">
              <img
                src={
                  !isEmpty(activeContent) &&
                  getImgFileFromVideo(
                    JSON.parse(activeContent.videoURLs)[0].url
                  )
                }
                className="promotion_body_section_row_leftImageBox_img"
              />
            </div>
            <div className="promotion_body_section_row_rightText">
              Von dir erstelltes Video <br />
              {!isEmpty(activeContent) && activeContent.title}
            </div>
          </div>
          <div className="promotion_body_section_row">
            Wird geteilt auf dem Kanal
          </div>
          <div className="promotion_body_section_row">
            <div className="promotion_body_section_row_leftProfileBox">
              <img
                src={
                  !isEmpty(activeContent) &&
                  "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg"
                }
                className="promotion_body_section_row_leftProfileBox"
              />
            </div>
            <div className="promotion_body_section_row_rightText">
              Instagram Kanal von Ciao_Bai_Bai
            </div>
          </div>
          <div className="promotion_body_section_row">Wird geteilt als</div>
          <div className="promotion_body_section_row">
            <select className="promotion_body_section_row_select">
              <option>
                Instagram Story -{" "}
                {formatEuroCents(activeContent.price_storypost_eurocent) + " €"}
              </option>
            </select>
          </div>
          <div className="promotion_body_section_row">Wird geteilt am</div>
          <div className="promotion_body_section_row">
            <select className="promotion_body_section_row_select">
              <option>09.09</option>
            </select>
          </div>

          <div className="promotion_body_section_title">Rechnungsdaten</div>
          <div className="promotion_body_section_row">
            <input
              type="text"
              className="promotion_body_section_row_input"
              placeholder="Firmenname"
            />
          </div>
          <div className="promotion_body_section_row">
            <div className="promotion_body_section_row_halfrow">
              <input
                type="text"
                className="promotion_body_section_row_input"
                placeholder="Vorname"
              />
            </div>
            <div className="promotion_body_section_row_halfrow">
              <input
                type="text"
                className="promotion_body_section_row_input"
                placeholder="Nachname"
              />
            </div>
          </div>
          <div className="promotion_body_section_row">
            <div className="promotion_body_section_row_halfrow">
              <input
                type="text"
                className="promotion_body_section_row_input"
                placeholder="Straßenname"
              />
            </div>
            <div className="promotion_body_section_row_halfrow">
              <input
                type="text"
                className="promotion_body_section_row_input"
                placeholder="Hausnummer"
              />
            </div>
          </div>
          <div className="promotion_body_section_row">
            <div className="promotion_body_section_row_halfrow">
              <input
                type="text"
                className="promotion_body_section_row_input"
                placeholder="Postleitzahl"
              />
            </div>
            <div className="promotion_body_section_row_halfrow">
              <input
                type="text"
                className="promotion_body_section_row_input"
                placeholder="Stadt"
              />
            </div>
          </div>

          <div className="promotion_body_section_title">Zahlungsdaten</div>
          <div className="promotion_body_section_row">
            <input
              type="text"
              className="promotion_body_section_row_input"
              placeholder="Kartennummer"
            />
          </div>
          <div className="promotion_body_section_row">
            <div className="promotion_body_section_row_halfrow">
              <input
                type="text"
                className="promotion_body_section_row_input"
                placeholder="Ablaufdatum"
              />
            </div>
            <div className="promotion_body_section_row_halfrow">
              <input
                type="text"
                className="promotion_body_section_row_input"
                placeholder="CVC"
                value={cvc}
                onChange={(e) => setCvc(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="promotion_bottombar">
        <div className="promotion_bottombar_price">
          <div className="promotion_bottombar_price_left">
            Preis (inkl. 19% MwSt)
          </div>
          <div className="promotion_bottombar_price_right">
            {formatEuroCents(activeContent.price_storypost_eurocent) + " €"}
          </div>
        </div>
        <div className="promotion_bottombar_legal">
          Mit klicken auf "Bezahlen" akzeptierst du die Bedingungen
        </div>
        {isLoading ? (
          <div className="promotion_bottombar_cta">
            <Spinner />
          </div>
        ) : (
          <>
            {formFilled ? (
              <div
                className="promotion_bottombar_cta"
                onClick={() => handleUpload()}>
                Bezahlen
              </div>
            ) : (
              <div className="promotion_bottombar_cta_notfilled">Ausfüllen</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

// einnahmen, leistungen, fortschritt, auftraggeber,

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;

const ArrowBottom = styled(MdContentPaste)`
  font-size: 18px;
  color: #474747;
`;

const DownloadIcon = styled(MdDownload)`
  font-size: 18px;
  color: #474747;
`;

const InstagramIcon = styled(FaInstagram)`
  font-size: 18px;
  color: #474747;
`;

const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 23px;
  color: #474747;
`;
