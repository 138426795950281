import React from "react";
import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid
    ${(props) => (props.type === "white" ? "#ffffff" : "#3498db")};
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: ${rotate360} 1.5s linear infinite;
`;

const Spinner = ({ type }) => {
  return <SpinnerContainer type={type} />;
};

export default Spinner;
